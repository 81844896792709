import service from '@/required/axios'

let $environment = 'web'
// let $environment = 'android'
// let $environment = 'ios'

let domian = '';

if ($environment === 'android') {
    const {data, code} = await service({
        url: 'system.version',
        method: "get",
    })

    if (code == 200) {
        domian = data.domain;
    }

} else {

    domian = window.location.hostname.split('.').slice(-2).join('.');
}

// 商城域名
let $mallHost = 'https://' + domian;
// 贷款域名
let $loanHost = 'https://loan.' + domian;
// 店铺跳转详情页
let $messageRouterLinkWeb = $mallHost + '#/order/order-index/order-detail'

/**
 *
 * 跳转地址
 *
 * @param url
 * @returns {boolean}
 */
function $openUrl(url)
{
    if ($environment === 'android') {
        plus.runtime.openURL(url)
        return true;
    }

    window.location.href = url

    return true;
}

/**
 * 是否展示
 *
 * @returns {boolean}
 */
function $showContent()
{
    return $environment === 'web';
}

export default{
    $environment,
    $openUrl,
    $showContent,
    $mallHost,
    $loanHost,
    $messageRouterLinkWeb
}